<template>
  <div class="login">
    <div class="login-panel">
      <div class="login-panel__left">
        <img class="login-panel__logo" src="@/assets/images/iSkin-logo.png" alt="iSkin尚行美健康聯合診所">
        <h2 class="login-panel__title">控場<span>/</span>預約<span v-if="!isProd" style="color:red">（測試站）</span></h2>
      </div>
      <div class="login-panel__form">
        <div class="login-panel__row">
          <input class="login-panel__input" type="text" id="username" placeholder="帳號" v-model="loginForm.username">
        </div>
        <div class="login-panel__row">
          <input class="login-panel__input" type="password" id="password" placeholder="密碼" v-model="loginForm.password" @keypress.enter.prevent="login">
        </div>
        <div class="login-panel__row">
          <button class="login-panel__btn-login" @click="login">登入</button>
          <p class="login-panel__error-msg" v-if="errMsg">{{errMsg}}</p>
          <p class="login-panel__checkbox">
            <input type="checkbox" id="remember-account" v-model="isRememberMe">
            <label for="remember-account">記住帳號</label>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '../service/auth.service'

export default {
  name: 'Login',
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        target: 'cms-frontend'
      },
      errMsg: '',
      isRememberMe: true
    }
  },
  computed: {
    isProd () {
      return process.env.VUE_APP_PLATFORM === 'PRODUCTION'
    }
  },
  methods: {
    login () {
      localStorage.setItem('isRememberMe', this.isRememberMe)
      if (this.isRememberMe) {
        localStorage.setItem('loginForm.username', this.loginForm.username)
      } else {
        localStorage.removeItem('loginForm.username')
      }
      AuthService.login(this.loginForm).then((data) => {
        if (data.error) {
          // 登入失敗並有錯誤訊息
          this.errMsg = data.error.message ?? '登入發生錯誤'
        } else {
          // 登入成功
          this.errMsg = ''
          this.$router.replace({ name: 'NScreen' })
        }
      })
    }
  },
  mounted () {
    this.isRememberMe = localStorage.getItem('isRememberMe') || true
    this.loginForm.username = this.isRememberMe ? (localStorage.getItem('loginForm.username') ?? '') : ''
  }
}
</script>

<style>

</style>
